import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './sidebar';

const BlogList = () => {
	const [blogs, setBlogs] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const blogsPerPage = 5; // Number of blogs per page
	useEffect(() => {
		const fetchBlogs = async () => {
			try {
				const response = await fetch('https://muslimsvoicenetwork.customdesignsavenue.com/api/blog.php?blog=All');
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				const data = await response.json();
				setBlogs(data);
			} catch (error) {
				setError(error.message);
			} finally {
				setLoading(false);
			}
		};

		fetchBlogs();
	}, []);

	let publicUrl = process.env.PUBLIC_URL + '/';
	let imageFetchPath = 'https://muslimsvoicenetwork.customdesignsavenue.com/public/img/';

	// Helper function to truncate content to 100 words
	const truncateContent = (content) => {
		return content.split(' ').slice(0, 100).join(' ') + '...';
	};

	// Pagination calculations
	const indexOfLastBlog = currentPage * blogsPerPage;
	const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
	const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);
	const totalPages = Math.ceil(blogs.length / blogsPerPage);

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	if (loading) {
		return (
			<div className="loader">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="#007bff"
					version="1.1"
					id="Capa_1"
					viewBox="0 0 32.707 32.707"
					width="100px"
					height="100px"
					style={{
						margin: "auto",
						background: "transparent",
						display: "block",
						shapeRendering: "auto",
					}}
				>
					<g>
						<path d="M21.477,4.511c0,2.487-2.021,4.508-4.508,4.508c-2.49,0-4.509-2.021-4.509-4.508c0-2.49,2.02-4.511,4.509-4.511 C19.458,0,21.477,2.021,21.477,4.511z M16.972,27.68c-1.386-0.002-2.513,1.119-2.517,2.508c-0.003,1.391,1.117,2.518,2.505,2.52 c1.39,0.004,2.517-1.117,2.519-2.506C19.484,28.811,18.361,27.684,16.972,27.68z M31.451,17.352c0-0.906-0.734-1.641-1.641-1.641 c-0.908,0-1.644,0.732-1.644,1.641c0,0.904,0.733,1.643,1.644,1.643C30.716,18.994,31.451,18.258,31.451,17.352z M6.995,17.352 c0-1.585-1.284-2.87-2.87-2.87s-2.869,1.285-2.869,2.87c0,1.584,1.283,2.869,2.869,2.869S6.995,18.936,6.995,17.352z M26.962,7.354 c-0.504-0.506-1.319-0.504-1.825,0c-0.505,0.506-0.505,1.328,0,1.832c0.506,0.506,1.321,0.506,1.825,0S27.466,7.86,26.962,7.354z M9.973,24.354c-1.152-1.146-3.019-1.146-4.17-0.002c-1.151,1.146-1.152,3.012,0,4.16c1.152,1.148,3.018,1.15,4.168,0 C11.125,27.367,11.125,25.502,9.973,24.354z M27.251,27.631c0.658-0.662,0.658-1.734-0.002-2.396 c-0.658-0.658-1.726-0.658-2.385,0.004c-0.658,0.66-0.658,1.732,0,2.395C25.525,28.293,26.591,28.293,27.251,27.631z M10.047,10.427c1.188-1.189,1.188-3.119,0-4.311c-1.188-1.189-3.115-1.189-4.305,0c-1.188,1.189-1.188,3.119,0.001,4.311 S8.858,11.618,10.047,10.427z" />
					</g>
					<style>
						{`
						@keyframes spin {
						0% { transform: rotate(0deg); }
						100% { transform: rotate(360deg); }
						}
						.loader {
						animation: spin 1s linear infinite;
						}
					`}
					</style>
				</svg>
			</div>


		);
	}


	if (error) {
		return <p>Error: {error}</p>;
	}

	return (
		<div className="blog-page-area pd-bottom-80 go-top">
			<div className="container">
				<div className="row">
					<div className="col-lg-9 pd-top-50">
						{currentBlogs.map((blog) => (
							<div key={blog.id} className="single-blog-inner">
								<div className="thumb">
									<img
										src={
											blog.post_image
												? blog.post_image
												: `${publicUrl}assets/img/blog/default.png`
										}
										alt="image"
									/>
								</div>
								<div className="single-blog-details">
									<div className="meta">
										<span className="author">
											<i className="fa fa-user" />
											{blog.author || 'Unknown'}
										</span>
										<span className="date">
											<i className="fa fa-calendar" />
											{new Date(blog.created_at).toLocaleDateString()}
										</span>
										{/* <span className="comments">
											<i className="fa fa-folder" />
											 {blog.category || 'N/A'}
										</span> */}
									</div>
									<h3>
										<Link to={{
											pathname: `/blog-details/${blog.id}`, // Existing route with blog ID
											state: { blog } // Passing the blog data as state
										}}>{blog.title}</Link>
									</h3>
									<Link
										className="btn btn-base"
										to={{
											pathname: `/blog-details/${blog.id}`, // Existing route with blog ID
											state: { blog } // Passing the blog data as state
										}}
									>
										Learn More
									</Link>

								</div>
							</div>
						))}

						{/* Pagination */}
						<nav className="mt-4">
							<ul className="pagination">
								<li className={`page-item prev ${currentPage === 1 ? 'disabled' : ''}`}>
									<button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
										<i className="fa fa-angle-left" />
									</button>
								</li>
								{Array.from({ length: totalPages }, (_, index) => (
									<li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
										<button className="page-link" onClick={() => handlePageChange(index + 1)}>
											{index + 1}
										</button>
									</li>
								))}
								<li className={`page-item next ${currentPage === totalPages ? 'disabled' : ''}`}>
									<button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
										<i className="fa fa-angle-right" />
									</button>
								</li>
							</ul>
						</nav>
					</div>
					<Sidebar />
				</div>
			</div>
		</div>
	);
};

export default BlogList;
