import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const TrendingV3 = ({ setParentLoading }) => {
	const [categories, setCategories] = useState([]);
	const [TrendingNews, setTrendingNews] = useState([]);
	const [WhatsNews, setWhatsNews] = useState([]);
	const [loading, setLoading] = useState({
		categories: true,
		TrendingNews: true,
		WhatsNews: true,
	});
	const [newsMessage, setNewsMessage] = useState('Loading News...');

	const publicUrl = process.env.PUBLIC_URL + '/';
	const imageFetchPath = 'https://muslimsvoicenetwork.customdesignsavenue.com/public/img/';
	const imageAlt = 'image';

	// Helper function to format the date
	const formatDate = (dateString) => {
		if (!dateString) return 'N/A';
		const date = new Date(dateString);
		return date.toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'long',
			day: 'numeric'
		});
	};

	// Fetch categories data
	const fetchCategories = async () => {
		try {
			const response = await fetch('https://muslimsvoicenetwork.customdesignsavenue.com/api/blog.php?category=news_categories&limit=4');
			const data = await response.json();
			setCategories(data);
			setLoading((prev) => ({ ...prev, categories: false }));
		} catch (error) {
			console.error('Error fetching categories:', error);
			setLoading((prev) => ({ ...prev, categories: false }));
		}
	};

	// Fetch trending news data
	const fetchTrendingNews = async () => {
		try {
			const response = await fetch('https://muslimsvoicenetwork.customdesignsavenue.com/api/blog.php?news=home_posts&section=trending_news&limit=5');
			const data = await response.json();
			setTrendingNews(data);
			setLoading((prev) => ({ ...prev, TrendingNews: false }));
		} catch (error) {
			console.error('Error fetching trending news:', error);
			setLoading((prev) => ({ ...prev, TrendingNews: false }));
		}
	};

	// Fetch what's news data
	const fetchWhatsNews = async () => {
		try {
			const response = await fetch('https://muslimsvoicenetwork.customdesignsavenue.com/api/blog.php?news=home_posts&section=whats_news&limit=2');
			const data = await response.json();
			setWhatsNews(data);
			setLoading((prev) => ({ ...prev, WhatsNews: false }));
		} catch (error) {
			console.error('Error fetching what\'s news:', error);
			setLoading((prev) => ({ ...prev, WhatsNews: false }));
		}
	};

	// Manage the news message change after 20 seconds
	useEffect(() => {
		const timer = setTimeout(() => {
			if (TrendingNews.length === 0) {
				setNewsMessage('No News Found');
			}
		}, 20000);

		return () => clearTimeout(timer);
	}, [TrendingNews]);

	useEffect(() => {
		fetchCategories();
		fetchTrendingNews();
		fetchWhatsNews();
	}, []);

	// Notify parent component when data has been fetched
	useEffect(() => {
		if (!loading.categories && !loading.TrendingNews && !loading.WhatsNews) {
			setParentLoading(false);
		}
	}, [loading, setParentLoading]);

	return (
		<div className="post-area pd-top-75 bg-sky pd-bottom-50 go-top">
			<div className="container">
				<div className="row">
					{/* Categories Section */}
					<Categories categories={categories} loading={loading.categories} imageFetchPath={imageFetchPath} publicUrl={publicUrl} imageAlt={imageAlt} formatDate={formatDate} />

					{/* Trending News Section */}
					<TrendingNewsSection TrendingNews={TrendingNews} loading={loading.TrendingNews} imageFetchPath={imageFetchPath} publicUrl={publicUrl} imageAlt={imageAlt} formatDate={formatDate} newsMessage={newsMessage} />

					{/* What's News Section */}
					<WhatsNewsSection WhatsNews={WhatsNews} loading={loading.WhatsNews} imageFetchPath={imageFetchPath} publicUrl={publicUrl} imageAlt={imageAlt} formatDate={formatDate} newsMessage={newsMessage} />

					<div className="col-lg-3 col-md-6">
						<div className="section-title">
							<h6 className="title">Join With Us</h6>
						</div>
						<div className="social-area-list mb-4">
							<ul>
								<li><a className="facebook" href="#" target='_blank'><i className="fa fa-facebook social-icon" /><span>12,300</span><span>Like</span> <i className="fa fa-plus" /></a></li>
								<li><a className="instagram" href="#" target='_blank'><i className="fa fa-instagram social-icon" /><span>52,400</span><span>Followers</span> <i className="fa fa-plus" /></a></li>
							</ul>
						</div>
						<div className="add-area">
							<Link to="/blog-details"><img className="w-100" src={publicUrl + "assets/img/add/7.jpg"} alt="img" /></Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

// Categories Component
const Categories = ({ categories, loading, imageFetchPath, publicUrl, imageAlt, formatDate }) => (
	<div className="col-lg-3 col-md-6">
		<div className="section-title">
			<h6 className="title">News Categories</h6>
		</div>
		<div className="post-slider owl-carousel">
			<div className="item">
				<div className="trending-post">
					{loading ? (
						<p>Loading Categories...</p>
					) : categories.length > 0 ? (
						categories.map((category, index) => (
							<div key={index} className="single-post-wrap style-overlay">
								<div className="thumb">
									<img src={category.category_image ? `${category.category_image}` : `${publicUrl}assets/img/blog/4.png`} alt={category.category || imageAlt} />
								</div>
								<div className="details">
									<div className="post-meta-single">
										<p><i className="fa fa-clock-o" />{formatDate(category.updated_at || category.created_at)}</p>
									</div>
									<h6 className="title"><Link to={`/cat-news/${category.id}`}>{category.category || category.slog} </Link></h6>
								</div>
							</div>
						))
					) : (
						<p>No Categories Found</p>
					)}
				</div>
			</div>
		</div>
	</div>
);

// Trending News Component
const TrendingNewsSection = ({ TrendingNews, loading, imageFetchPath, publicUrl, imageAlt, formatDate, newsMessage }) => (
	<div className="col-lg-3 col-md-6">
		<div className="section-title">
			<h6 className="title">Trending News</h6>
		</div>
		<div className="post-slider owl-carousel">
			<div className="item">
				{loading ? (
					<p>{newsMessage}</p>
				) : TrendingNews.length > 0 ? (
					TrendingNews.map((post, index) => (
						<div key={index} className="single-post-list-wrap">
							<div className="media">
								<div className="media-left">
									<img className="img-100" src={post.post_image ? `${post.post_image}` : `${publicUrl}assets/img/post/list/1.png`} alt={post.title || imageAlt} />
								</div>
								<div className="media-body">
									<div className="details">
										<div className="post-meta-single">
											<ul>
												<li><i className="fa fa-clock-o" />{formatDate(post.updated_at || post.created_at)}</li>
											</ul>
										</div>
										<h6 className="title"><Link to={{ pathname: `/news-details/${post.id}`, state: { TrendingNews: post } }}>{post.title} </Link></h6>
									</div>
								</div>
							</div>
						</div>
					))
				) : (
					<p>{newsMessage}</p>
				)}
			</div>
		</div>
	</div>
);

// What's News Component
const WhatsNewsSection = ({ WhatsNews, loading, imageFetchPath, publicUrl, imageAlt, formatDate, newsMessage }) => (
	<div className="col-lg-3 col-md-6">
		<div className="section-title">
			<h6 className="title">What’s new</h6>
		</div>
		<div className="post-slider owl-carousel">
			<div className="item">
				{loading ? (
					<p>{newsMessage}</p>
				) : WhatsNews.length > 0 ? (
					WhatsNews.map((post, index) => (
						<div key={index} className="single-post-wrap">
							<div className="thumb">
								<img src={post.post_image ? `${post.post_image}` : `${publicUrl}assets/img/post/8.png`} alt={post.title || imageAlt} />
							</div>
							<div className="details">
								<div className="post-meta-single mb-4 pt-1">
									<ul>
										<li><Link className="tag-base tag-blue" to="/cat-page">{post.slog}</Link></li>
										<li><i className="fa fa-clock-o" />{formatDate(post.updated_at || post.created_at)}</li>
									</ul>
								</div>
								<h6 className="title"><Link to={{ pathname: `/news-details/${post.id}`, state: { WhatsNews: post } }}>{post.title}</Link></h6>
							</div>
						</div>
					))
				) : (
					<p>{newsMessage}</p>
				)}
			</div>
		</div>
	</div>
);

export default TrendingV3;
