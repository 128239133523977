import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const BannerV2 = ({ setParentLoading }) => {
  const [news, setnews] = useState([]);
  const [categories, setCategories] = useState([]);
  const [latestNews, setLatestNews] = useState([]);
  const [loading, setLoading] = useState({
    news: true,
    categories: true,
    latestNews: true,
  });
  const [newsMessage, setNewsMessage] = useState('Loading News...');

  const publicUrl = process.env.PUBLIC_URL + '/';
  const imageFetchPath = 'https://muslimsvoicenetwork.customdesignsavenue.com/public/img/';
  const imageAlt = 'image';

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const fetchData = async () => {
    setParentLoading(true); // Start loading state in parent
    const cacheBuster = new Date().getTime(); // Generates a unique timestamp
    try {
      const [newsResponse, categoriesResponse, latestNewsResponse] = await Promise.all([
        fetch(`https://muslimsvoicenetwork.customdesignsavenue.com/api/blog.php?news=home_posts&section=home_banner&limit=5&_=${cacheBuster}`),
        fetch(`https://muslimsvoicenetwork.customdesignsavenue.com/api/blog.php?category=news_categories&_=${cacheBuster}`),
        fetch(`https://muslimsvoicenetwork.customdesignsavenue.com/api/blog.php?news=home_posts&section=latest_news&limit=5&_=${cacheBuster}`)
      ]);

      const [newsData, categoriesData, latestNewsData] = await Promise.all([
        newsResponse.json(),
        categoriesResponse.json(),
        latestNewsResponse.json()
      ]);

      setnews(newsData);
      setCategories(categoriesData);
      setLatestNews(latestNewsData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading({ news: false, categories: false, latestNews: false });
      setParentLoading(false); // End loading state in parent
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (latestNews.length === 0) {
        setNewsMessage('No News Found');
      }
    }, 20000);

    return () => clearTimeout(timer);
  }, [latestNews]);

  const [isMediumScreen, setIsMediumScreen] = useState(
    window.innerWidth >= 768 && window.innerWidth < 1200
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMediumScreen(window.innerWidth >= 700 && window.innerWidth < 1200);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const truncateTitle = (title) => {
    const words = title.split(' ');
    return words.length > 5 ? words.slice(0, 5).join(' ') + '...' : title;
  };

  if (loading.news || loading.categories || loading.latestNews) {
    return null; // Return null while loading, so that the parent component can show the loader
  }

  return (
    <div className="post-area banner-post-area pt-4 pd-bottom-70">
      <div className="container">
        <div className="row">
          <News
            news={news}
            imageFetchPath={imageFetchPath}
            publicUrl={publicUrl}
            imageAlt={imageAlt}
            formatDate={formatDate}
            isMediumScreen={isMediumScreen}
            truncateTitle={truncateTitle}
            
          />
          <LatestNewsSection
            latestNews={latestNews}
            imageFetchPath={imageFetchPath}
            publicUrl={publicUrl}
            imageAlt={imageAlt}
            formatDate={formatDate}
            newsMessage={newsMessage}
          />
          <Categories
            categories={categories}
            imageFetchPath={imageFetchPath}
            publicUrl={publicUrl}
            imageAlt={imageAlt}
          />
        </div>
      </div>
    </div>  
  );
};

// Blog Component
const News = ({ news, imageFetchPath, publicUrl, imageAlt, formatDate, isMediumScreen, truncateTitle }) => (
  <div className="col-lg-6">
    {news.length > 0 ? (
      <>
        <div className="single-post-wrap style-overlay">
          <div className="thumb">
            <img
              src={news[0].post_image ? `${news[0].post_image}` : `${publicUrl}assets/img/blog/default.png`}
              alt={news[0].title || imageAlt}
            />
            <Link className="tag-base tag-blue" to={`/cat-blog/${news[0].category}`}>
              {news[0].category}
            </Link>
          </div>
          <div className="details">
            <div className="post-meta-single">
              <p>
                <i className="fa fa-clock-o" />
                {formatDate(news[0].updated_at || news[0].created_at)}
              </p>
            </div>
            <h5 className="title">
              <Link to={{ pathname: `/news-details/${news[0].id}`}}>{news[0].title}</Link>
            </h5>
          </div>
        </div>

        <div className="row">
          {news.slice(1, 5).map((news, index) => (
            <div className="col-lg-6 col-sm-6" key={index}>
              <div className="single-post-wrap style-overlay">
                <div className="thumb">
                  <img
                    src={news.post_image ? `${news.post_image}` : `${publicUrl}assets/img/news/default.png`}
                    alt={news.title || imageAlt}
                  />
                </div>
                <div className="details">
                  <div className="post-meta-single">
                    <p>
                      <i className="fa fa-clock-o" />
                      {formatDate(news.updated_at || news.created_at)}
                    </p>
                  </div>
                  <h6 className="title">
                    <Link to={{ pathname: `/news-details/${news.id}`}}>{isMediumScreen ? truncateTitle(news.title) : news.title}</Link>
                  </h6>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    ) : (
      <p>No Post Found</p>
    )}
  </div>
);

// Latest News Component
const LatestNewsSection = ({ latestNews, imageFetchPath, publicUrl, imageAlt, formatDate, newsMessage }) => (
  <div className="col-lg-3 col-sm-6">
    <div className="section-title style-two mb-4">
      <h6 className="title">Latest News</h6>
    </div>
    {latestNews.length > 0 ? (
      latestNews.map((post, index) => (
        <div key={index} className="single-post-list-wrap">
          <div className="media">
            <div className="media-left">
              <img
                src={post.post_image ? `${post.post_image}` : `${publicUrl}assets/img/post/list/1.png`}
                alt={post.title || imageAlt}
                width={100}
              />
            </div>
            <div className="media-body">
              <div className="details">
                <div className="post-meta-single">
                  <ul>
                    <li>
                      <i className="fa fa-clock-o" />
                      {formatDate(post.updated_at || post.created_at)}
                    </li>
                  </ul>
                </div>
                <h6 className="title">
                  <Link to={{ pathname: `/news-details/${post.id}`, state: { latestNews: post } }}>{post.title}</Link>
                </h6>
              </div>
            </div>
          </div>
        </div>
      ))
    ) : (
      <p>{newsMessage}</p>
    )}
  </div>
);

// Categories Component
const Categories = ({ categories, imageFetchPath, publicUrl, imageAlt }) => (
  <div className="col-lg-3 col-sm-6 widget-category">
    <div className="section-title style-two mb-4">
      <h6 className="title">Category</h6>
    </div>
    <div className="row custom-gutters-14">
      {categories.length > 0 ? (
        categories.map((category, index) => (
          <div key={index} className="col-lg-12 col-sm-6">
            <div className="single-category-inner">
              <img
                src={category.category_image ? `${category.category_image}` : `${publicUrl}assets/img/blog/4.png`}
                alt={category.category || imageAlt}
              />
              <Link className="tag-base tag-blue" to={`/cat-news/${category.id}`}>
                {category.category}
              </Link>
              <h6 className="title">
                <Link to={`/category/${category.id}`}>{category.name}</Link>
              </h6>
            </div>
          </div>
        ))
      ) : (
        <p>No Categories Found</p>
      )}
    </div>
  </div>
);

export default BannerV2;




// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import Loader from '../global-components/loader';


// const BannerV2 = () => {
//   const [news, setnews] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [latestNews, setLatestNews] = useState([]);
//   const [loading, setLoading] = useState({
//     news: true,
//     categories: true,
//     latestNews: true,
//   });
//   const [newsMessage, setNewsMessage] = useState('Loading News...');

//   const publicUrl = process.env.PUBLIC_URL + '/';
//   const imageFetchPath = 'https://muslimsvoicenetwork.customdesignsavenue.com/public/img/';
//   const imageAlt = 'image';

//   // Helper function to format the date
//   const formatDate = (dateString) => {
//     if (!dateString) return 'N/A';
//     const date = new Date(dateString);
//     return date.toLocaleDateString('en-US', {
//       year: 'numeric',
//       month: 'long',
//       day: 'numeric'
//     });
//   };

//   // Fetch news data
//   const fetchnews = async () => {
//     try {
//       const response = await fetch('https://muslimsvoicenetwork.customdesignsavenue.com/api/blog.php?blog=home_posts&section=home_banner&limit=3');
//       const data = await response.json();
//       setnews(data);
//       setLoading((prev) => ({ ...prev, news: false }));
//     } catch (error) {
//       console.error('Error fetching news:', error);
//       setLoading((prev) => ({ ...prev, news: false }));
//     }
//   };

//   // Fetch categories data
//   const fetchCategories = async () => {
//     try {
//       const response = await fetch('https://muslimsvoicenetwork.customdesignsavenue.com/api/blog.php?category=blog_categories');
//       const data = await response.json();
//       setCategories(data);
//       setLoading((prev) => ({ ...prev, categories: false }));
//     } catch (error) {
//       console.error('Error fetching categories:', error);
//       setLoading((prev) => ({ ...prev, categories: false }));
//     }
//   };

//   // Fetch latest news data
//   const fetchLatestNews = async () => {
//     try {
//       const response = await fetch('https://muslimsvoicenetwork.customdesignsavenue.com/api/blog.php?news=home_posts&section=latest_news&limit=6');
//       const data = await response.json();
//       setLatestNews(data);
//       setLoading((prev) => ({ ...prev, latestNews: false }));
//     } catch (error) {
//       console.error('Error fetching latest news:', error);
//       setLoading((prev) => ({ ...prev, latestNews: false }));
//     }
//   };

//   // Manage the news message change after 20 seconds
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       if (latestNews.length === 0) {
//         setNewsMessage('No News Found');
//       }
//     }, 20000);

//     return () => clearTimeout(timer);
//   }, [latestNews]);

//   useEffect(() => {
//     fetchnews();
//     fetchCategories();
//     fetchLatestNews();
//   }, []);

//   // Check if any data is still loading
//   const isLoading = Object.values(loading).some(status => status);

//   return (
//     <div className="post-area banner-post-area pt-4 pd-bottom-70">
//       <div className="container">
//         {isLoading ? (
//           <Loader />
//         ) : (
//           <div className="row">
//             {/* news Section */}
//             <news news={news} imageFetchPath={imageFetchPath} publicUrl={publicUrl} imageAlt={imageAlt} formatDate={formatDate} />

//             {/* Latest News Section */}
//             <LatestNewsSection latestNews={latestNews} imageFetchPath={imageFetchPath} publicUrl={publicUrl} imageAlt={imageAlt} formatDate={formatDate} newsMessage={newsMessage} />

//             {/* Categories Section */}
//             <Categories categories={categories} imageFetchPath={imageFetchPath} publicUrl={publicUrl} imageAlt={imageAlt} />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// // news Component
// const news = ({ news, imageFetchPath, publicUrl, imageAlt, formatDate }) => (
//   <div className="col-lg-6">
//     {news.length > 0 ? (
//       <>
//         {/* First Blog Post */}
//         <div className="single-post-wrap style-overlay">
//           <div className="thumb">
//             <img src={news[0].post_image ? imageFetchPath + 'blog/' + news[0].post_image : `${publicUrl}assets/img/blog/default.png`} alt={news[0].title || imageAlt} />
//             <Link className="tag-base tag-blue" to={`/cat-blog/${news[0].category}`}>
//               {news[0].category}
//             </Link>
//           </div>
//           <div className="details">
//             <div className="post-meta-single">
//               <p>
//                 <i className="fa fa-clock-o" />
//                 {formatDate(news[0].updated_at || news[0].created_at)}
//               </p>
//             </div>
//             <h5 className="title">
//               <Link to={{ pathname: `/blog-details/${news[0].id}`, state: { news } }}>{news[0].title}</Link>
//             </h5>
//           </div>
//         </div>

//         {/* Second and Third Blog Posts */}
//         <div className="row">
//           {news.slice(1, 3).map((blog, index) => (
//             <div className="col-lg-6 col-sm-6" key={index}>
//               <div className="single-post-wrap style-overlay">
//                 <div className="thumb">
//                   <img src={blog.post_image ? imageFetchPath + 'blog/' + blog.post_image : `${publicUrl}assets/img/blog/default.png`} alt={blog.title || imageAlt} />
//                 </div>
//                 <div className="details">
//                   <div className="post-meta-single">
//                     <p>
//                       <i className="fa fa-clock-o" />
//                       {formatDate(blog.updated_at || blog.created_at)}
//                     </p>
//                   </div>
//                   <h6 className="title">
//                     <Link to={{ pathname: `/blog-details/${blog.id}`, state: { news } }}>{blog.title}</Link>
//                   </h6>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </>
//     ) : (
//       <p>No news Found</p>
//     )}
//   </div>
// );

// // Latest News Component
// const LatestNewsSection = ({ latestNews, imageFetchPath, publicUrl, imageAlt, formatDate, newsMessage }) => (
//   <div className="col-lg-3 col-sm-6">
//     <div className="section-title style-two mb-4">
//       <h6 className="title">Latest News</h6>
//     </div>
//     {latestNews.length > 0 ? (
//       latestNews.map((post, index) => (
//         <div key={index} className="single-post-list-wrap">
//           <div className="media">
//             <div className="media-left">
//               <img src={post.post_image ? `${imageFetchPath}news/${post.post_image}` : `${publicUrl}assets/img/post/list/1.png`} alt={post.title || imageAlt} width={100} />
//             </div>
//             <div className="media-body">
//               <div className="details">
//                 <div className="post-meta-single">
//                   <ul>
//                     <li>
//                       <i className="fa fa-clock-o" />
//                       {formatDate(post.updated_at || post.created_at)}
//                     </li>
//                   </ul>
//                 </div>
//                 <h6 className="title">
//                   <Link to={{ pathname: `/news-details/${post.id}`, state: { latestNews: post } }}>{post.title}</Link>
//                 </h6>
//               </div>
//             </div>
//           </div>
//         </div>
//       ))
//     ) : (
//       <p>{newsMessage}</p>
//     )}
//   </div>
// );

// // Categories Component
// const Categories = ({ categories, imageFetchPath, publicUrl, imageAlt }) => (
//   <div className="col-lg-3 col-sm-6 widget-category">
//     <div className="section-title style-two mb-4">
//       <h6 className="title">Category</h6>
//     </div>
//     <div className="row custom-gutters-14">
//       {categories.length > 0 ? (
//         categories.map((category, index) => (
//           <div key={index} className="col-sm-6">
//             <div className="single-category-inner">
//               <img src={category.category_image ? `${imageFetchPath}blog_category/${category.category_image}` : `${publicUrl}assets/img/blog/4.png`} alt={category.category || imageAlt} />
//               <Link className="tag-base tag-blue" to={`/cat-blog/${category.id}`}>
//                 {category.slog}
//               </Link>
//             </div>
//           </div>
//         ))
//       ) : (
//         <p>No Categories Found</p>
//       )}
//     </div>
//   </div>
// );

// export default BannerV2;



