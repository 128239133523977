import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      trendingPosts: [],
      recentPosts: [],
      popularPosts: [], // Assuming this data still comes from the same API (modify if needed)
    };
  }

  
  componentDidMount() {
    // Fetch Categories
    fetch('https://muslimsvoicenetwork.customdesignsavenue.com/api/blog.php?category=news_categories')
      .then(response => response.json())
      .then(data => {
        if (Array.isArray(data)) {
          this.setState({ categories: data });
        } else {
          console.error("Unexpected data format for categories:", data);
        }
      })
      .catch(error => console.error('Error fetching categories:', error));

    // Fetch Trending Posts
    fetch('https://muslimsvoicenetwork.customdesignsavenue.com/api/blog.php?news=trending_posts&type=news&limit=4')
      .then(response => response.json())
      .then(data => {
        this.setState({ trendingPosts: data });
      })
      .catch(error => console.error('Error fetching trending posts:', error));

    // Fetch Recent Posts
    fetch('https://muslimsvoicenetwork.customdesignsavenue.com/api/blog.php?news=recent_posts&type=news')
      .then(response => response.json())
      .then(data => {
        this.setState({ recentPosts: data });
      })
      .catch(error => console.error('Error fetching recent posts:', error));

    // Fetch Popular Posts (assuming it's fetched from the same API or adjust accordingly)
    fetch('https://muslimsvoicenetwork.customdesignsavenue.com/api/blog.php?news=popular_posts&type=news')
      .then(response => response.json())
      .then(data => {
        this.setState({ popularPosts: data });
      })
      .catch(error => console.error('Error fetching popular posts:', error));
  }

  render() {
    const { categories, trendingPosts, recentPosts, popularPosts } = this.state;
    let publicUrl = process.env.PUBLIC_URL + '/';
    let imagealt = 'image';
    let imageFetchPath = 'https://muslimsvoicenetwork.customdesignsavenue.com/public/img/';
    let anchor = '#';

    const formatDate = (dateString) => {
      if (!dateString) return 'N/A'; // Handle cases where the date might be null or undefined
      const date = new Date(dateString);
      return date.toLocaleDateString(); // Adjust format as needed
    };
    
    return (
      <div className="col-lg-3 pd-top-50 go-top">
        <div className="category-sitebar">
          {/* Categories Section */}
          <div className="widget widget-category">
            <h6 className="widget-title">Category</h6>
            <div className="row custom-gutters-14">
              {categories && categories.length > 0 ? (
                categories.map((category, index) => (
                  <div className="col-lg-12 col-sm-6" key={category.id || index}>
                    <div className="single-category-inner">
                      <img src={category.category_image ? `${category.category_image}` : `${publicUrl}assets/img/news/4.png`} alt={category.category || 'No category'} />
                      <Link className="tag-base tag-blue" to={`/cat-news/${category.id}`}>{category.category}</Link>
                    </div>
                  </div>
                ))
              ) : (
                <p>No categories available</p>
              )}
            </div>
          </div>
  
          {/* Advertisement Section */}
          <div className="widget widget-add">
              <div className="add">
                <img className="w-100" src={publicUrl+"assets/img/add/3.png"} alt="img" />
              </div>
            </div>
  
          {/* Social Section */}
          <div className="widget widget-social">
            <h6 className="widget-title">Join Us</h6>
            <ul className="social-area social-area-2">
              <li><a className="facebook-icon" href="https://www.facebook.com/profile.php?id=61566769855926" target='_blank'><i className="fa fa-facebook" /></a></li>
              <li><a className="instagram-icon" href="#"><i className="fa fa-instagram" /></a></li>
            </ul>
          </div>
  
          {/* Trending Posts Section */}
          <div className="widget">
              <h6 className="widget-title">Trending</h6>
                <div className="item">
                  <div className="trending-post">
                    {trendingPosts && trendingPosts.length > 0 ? (
                    trendingPosts.map((post, index) => (
                      <div className="single-post-wrap style-overlay" key={post.id || index}>
                      <div className="thumb">
                        <img src={post.post_image ? `${post.post_image}` : `${publicUrl}assets/img/blog/4.png`} alt={post.title || 'No post'} />
                      </div>
                      <div className="details">
                        <div className="post-meta-single">
                          <p><i className="fa fa-clock-o" />{formatDate(post.updated_at) || formatDate(post.created_at)}</p>
                        </div>
                        <h6 className="title"><Link
                                to={{
                                  pathname: `/news-details/${post.id}`, // Existing route with blog ID
                                  state: { trendingPosts: post } // Passing the blog data as state
                                }}
                              >
                                {post.title.split(' ').length > 4 
                              ? `${post.title.split(' ').slice(0, 4).join(' ')}...` 
                              : post.title}
                              </Link></h6>
                      </div>
                    </div>
                    ))
                  ) : (
                    <p>No Post available</p>
                  )}
                  </div>
                </div>
            </div>
  
          {/* Tabbed Section */}
          <div className="widget">
            <div className="nxp-tab-inner nxp-tab-post-two mb-4">
              <ul className="nav nav-tabs" id="nx1" role="tablist">
                <li className="nav-item" role="presentation">
                  <a className="nav-link active" id="nx1-tab-1" data-toggle="pill" href="#nx1-tabs-1" role="tab" aria-selected="true">
                    Recent
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a className="nav-link" id="nx1-tab-2" data-toggle="pill" href="#nx1-tabs-2" role="tab" aria-selected="false">
                    Popular
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content" id="nx1-content">
              {/* Recent Posts Tab */}
              <div className="tab-pane fade show active" id="nx1-tabs-1" role="tabpanel">
                {recentPosts && recentPosts.length > 0 ? (
                  recentPosts.map((post, index) => (
                    <div className="single-post-list-wrap" key={index}>
                      <div className="media">
                        <div className="media-left">
                          <img src={post.post_image ? `${post.post_image}` : `${publicUrl}assets/img/post/4.png`} width={100} alt="img" />
                        </div>
                        <div className="media-body">
                          <div className="details">
                            <div className="post-meta-single">
                              <ul>
                                <li><i className="fa fa-clock-o" />{formatDate(post.updated_at) || formatDate(post.created_at)}</li>
                              </ul>
                            </div>
                            <h6 className="title">
                              <Link 
                                to={{
                                  pathname: `/news-details/${post.id}`, // Existing route with blog ID
                                  state: { recentPosts: post } // Passing the blog data as state
                                }}
                              >
                                {post.title}
                              </Link>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No recent posts available</p>
                )}
              </div>
  
              {/* Popular Posts Tab */}
              <div className="tab-pane fade" id="nx1-tabs-2" role="tabpanel">
                {popularPosts && popularPosts.length > 0 ? (
                  popularPosts.map((post, index) => (
                    <div className="single-post-list-wrap" key={index}>
                      <div className="media">
                        <div className="media-left">
                          <img src={post.post_image ? `${post.post_image}` : `${publicUrl}assets/img/post/list/9.png`} width={100} alt="img" />
                        </div>
                        <div className="media-body">
                          <div className="details">
                            <div className="post-meta-single">
                              <ul>
                                <li><i className="fa fa-clock-o" />{formatDate(post.updated_at) || formatDate(post.created_at)}</li>
                              </ul>
                            </div>
                            <h6 className="title"><Link 
                                to={{
                                  pathname: `/news-details/${post.id}`, // Existing route with blog ID
                                  state: { popularPosts: post } // Passing the blog data as state
                                }}
                              >
                                {post.title}
                              </Link></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No popular posts available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
}

export default Sidebar;
